<template>
  <div class="home">
    <v-tabs
      show-arrows
      centered
      dark
      class="tabs__bar__sty"
      v-model="tab"
    >
      <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
      <v-btn
        v-for="(item, id) in tabitems"
        :key="'A' + item.store_uuid"
        :class="'Color Color'+color_arr[id]"
        text
        @click="getProduct(id)"
      >
        {{ item.store_name }}
      </v-btn>
    </v-tabs>
    <v-img
      v-show="bannerImg !== ''"
      :src="bannerImg"
      class="img__pos__sty"
      @click="goURL(image_click_url)"
    ></v-img>
    <iframe
      v-show="bannerVideo !== ''"
      :src="bannerVideo"
      width="100%"
      class="video__sty"
    ></iframe>
    <v-tabs-items class="ma-4" v-model="tab" v-show="show.shoplist_area">
      <v-tab-item
        v-for="(item, idx) in shoplist"
        :key="'B' + idx"
      >
        <v-container fluid>
          <v-row>
            <v-col
              v-for="(subitem, idx) in item"
              :key="'C' + idx"
              class="subitem__area__sty"
              cols="6"
              sm="4"
              md="3"
            >
              <v-card
                class="mx-auto my-2"
                max-width="374"
                outlined
                @click="goProductInfo(subitem.uuid)"
              >
                <v-img
                  height="115"
                  :src="subitem.image_url"
                  lazy-src="https://picsum.photos/id/11/10/6"
                  aspect-ratio="1"
                  class="grey lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-text class="subitem__sty">
                  {{ subitem.name }}
                  <div>
                    <font class="spec__sty">NT${{ subitem.price }}</font>
                    <del class="pl-3 spec__sty">NT${{ subitem.origin_price }}</del>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
    <div class="no__production__pos" v-show="!show.shoplist_area">
      <v-icon>mdi-cart-remove</v-icon>
      <p>即將上架</p>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'SpecialStore',
  data: () => ({
    access_key: 'rIhTQzuOwZ54asxqRMNkYVF3n6UA7v9c',
    tab: 0,
    tabitems: [],
    // shoplist: [],
    shoplist: {
      shoplist:[]
    },
    show: {
      shoplist_area: true
    },
    color_arr: [],
    bannerImg: '',
    bannerVideo: '',
    image_click_url: '',
  }),
  methods: {
    // 查詢商店uuid
    findTabStore(storeKey, tabArray) {
      for (let i = 0; i < tabArray.length; i++) {
        if (tabArray[i].store_uuid === storeKey) {
          return i;
        }
      }
      return 0;
    },
    getTabItems() {
      this.axios({
        method: 'post',
        url: `https://${process.env.VUE_APP_API}.cheerlife.io/cart/v1/productions/store-list`,
        data: {
          access_key: process.env.VUE_APP_ACCESSKEY,
        },
      })
        .then((response) => {
          const temp_store_list = response.data.store_list;
          const get_store = this.$route.query.store;
          this.tabitems[0] = temp_store_list[this.findTabStore(get_store,temp_store_list)];
          console.log(this.tabitems[0]);
          // url有商店uuid
          if (get_store !== undefined && get_store !== '') {
            this.getProduct(get_store);
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    },
    getProduct(store_uuid) {
      this.color_arr = [];
      for (let i = 0; i < this.tabitems.length; i++) {
        this.color_arr.push(1);
      }
      this.color_arr[0] = 0;
      this.axios({
        method: 'post',
        url: `https://${process.env.VUE_APP_API}.cheerlife.io/cart/v1/productions/product-list`,
        data: {
          access_key: process.env.VUE_APP_ACCESSKEY,
          store_uuid: store_uuid
        },
      })
        .then((response) => {
          // banner
          if (response.data.shop_image_url !== '' && response.data.shop_image_url !== undefined) {
            this.bannerImg = response.data.shop_image_url;
          } else {
            this.bannerImg = '';
          }
          // url
          if (response.data.image_click_url !== '' && response.data.image_click_url !== undefined) {
            this.image_click_url = response.data.image_click_url;
          } else {
            this.image_click_url = '';
          }
          // video
          if (response.data.shop_video_url !== '' && response.data.shop_video_url !== undefined) {
            this.bannerVideo = response.data.shop_video_url;
          } else {
            this.bannerVideo = '';
          }
          // 商品
          if (response.data.shoplist !== null) {
            this.shoplist = response.data;
            delete this.shoplist.shop_image_url;
            this.show.shoplist_area = true;
          } else {
            this.shoplist = {
              shoplist: [
              ]
            };
            this.show.shoplist_area = false;
          }
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    },
    goProductInfo(product_uuid) {
      //  判斷是不是從app進入
      if (navigator.userAgent.indexOf('cheerlife') !== -1) {
        const get_phone = this.$route.query.phone;
        // 商店uuid
        const get_store = this.$route.query.store;
        if (get_phone !== undefined && get_phone !== '') {
          console.log("t1");
          if (get_store !== undefined && get_store !== '') {
            this.$router.push(`/special_product?uid=${product_uuid}&phone=${get_phone}&store=${get_store}`);
          } else {
            this.$router.push(`/special_product?uid=${product_uuid}&phone=${get_phone}`);
          }
        } else {
          console.log("t2");
          if (get_store !== undefined && get_store !== '') {
            this.$router.push(`/special_product?uid=${product_uuid}&store=${get_store}`);
          } else {
            this.$router.push(`/special_product?uid=${product_uuid}`);
          }
        }
      } else {
        console.log("t3");
        this.$router.push(`/special_product?uid=${product_uuid}`);
      }
    },
    // 外部連結
    goURL(getlink) {
      if (getlink !== '') {
        window.location.href = getlink;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getTabItems();
  },
  watch: {
  },
}
</script>
<style lang="sass">
</style>
<style scoped lang="sass">
  .subitem__area__sty
    padding: 0 5px
  .subitem__sty
    padding: 15px 10px
    color: #000000
    font-style: normal
    font-weight: normal
    font-size: 15px
  del.spec__sty
    font-size: 15px
    color: #ED7978
  .tabs__bar__sty
    background-color: #352d40
    border-color: #352d40
  .Color0
    border-bottom: 2px solid #fff
    border-radius: 0
    background-color: #fdd35c
    color: #352d40
    font-weight: bolder
  .v-btn:not(.v-btn--round).v-size--default
    height: unset
  .home
    position: relative
  .no__production__pos
    position: absolute
    left: 50%
    transform: translate(-50%, 85%)
  .no__production__pos i
    font-size: 150px
    color: #352D40
  .no__production__pos p
    font-size: 30px
    text-align: center
    color: #352D40
  .img__pos__sty
    cursor: pointer
  @media screen and (min-width: 1024px)
    .video__sty
      height: 440px
</style>
